<template>
	<lassoLayout class="page-release-removed">
		<div class="p-release-removed">
			<div class="content-page">
				<div class="block_release_removed">
					<h2>
						Success!
					</h2>
					<div class="description">
						Your release has been removed.
					</div>
					<div class="illustration"></div>

					<div class="action_buttons">
						<router-link :to="{name: 'explore'}" class="tetriatary_btn medium" v-ripple>Home</router-link>
						<router-link :to="{name: 'uploads'}" class="primary_btn medium" v-ripple>Go to uploads</router-link>
					</div>
				</div>
			</div>
		</div>
	</lassoLayout>
</template>

<script>
import lassoLayout from "@/layouts/lassoLayout";
export default {
	name: "releaseRemoved",
	components: {
		lassoLayout
	}
}
</script>

<style scoped>

</style>